<template>
    <div>
        <a-result status="403" title="暂无登陆权限" sub-title="请联系系统管理员给您分配权限">

        </a-result>
    </div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
    name: "mobile-auth"
}
</script>

<style scoped>

</style>